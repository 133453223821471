import { scmpPlus } from "@product/scmp-sdk";
import { Fragment, type ReactNode, Suspense, useCallback } from "react";
import { graphql, readInlineData, useLazyLoadQuery } from "react-relay";

import { HundredTopTablesDiscoveryWidget } from "scmp-app/components/hundred-top-tables-discovery-widget";
import { PostMagazineDiscoveryWidget } from "scmp-app/components/post-magazine/discovery-widget";
import { ChinaScienceDiscoveryWidget } from "scmp-app/components/rhs-module/china-science-discovery-widget";
import { OpenQuestionsSeriesDiscoveryWidget } from "scmp-app/components/rhs-module/open-questions-series-discovery-widget";
import { AiArticleWidget } from "scmp-app/components/section/ai-article-widget";
import { TopicAds } from "scmp-app/components/topic/topic-ads";
import { AiEngineDataType } from "scmp-app/lib/ai-engine/enums";
import { RHSModule2, RHSModule3 } from "scmp-app/pages/topic/styles";
import type { hooksTopicWidgetLazyLoadQuery } from "scmp-app/queries/__generated__/hooksTopicWidgetLazyLoadQuery.graphql";
import type { hooksTopicWidgetQuery$key } from "scmp-app/queries/__generated__/hooksTopicWidgetQuery.graphql";

import { TopicDiscoveryWidgetType } from "./consts";
import type { TopicConfig, TopicWidget } from "./types";

type UseTopicsAndAdsWidgetsProperty = {
  contentItemLength: number;
  hasSponsor: boolean;
  reference: hooksTopicWidgetQuery$key;
};
export const useTopicsAndAdsWidgets = ({
  contentItemLength,
  hasSponsor,
  reference,
}: UseTopicsAndAdsWidgetsProperty) => {
  const data = readInlineData(
    graphql`
      fragment hooksTopicWidgetQuery on Query
      @inline
      @argumentDefinitions(entityId: { type: "String!" }) {
        topic(filter: { entityId: $entityId }) {
          entityId
          ...hooksTopic
          ...topicAdsTopic
          ...topicMetadataDisplayTopic
        }
        appConfig(filter: { entityId: "scmp_pwa_settings" }) {
          json
        }
      }
    `,
    reference,
  );

  const getSpecificWidgets = useCallback(() => {
    const specificWidgets = data.appConfig?.json as TopicConfig;

    if (specificWidgets?.topic_rhs_modules) {
      for (const [type, entityIds] of Object.entries(specificWidgets.topic_rhs_modules)) {
        if (entityIds.includes(data.topic.entityId)) {
          return type as TopicWidget;
        }
      }
    }
    return null;
  }, [data.appConfig?.json, data.topic.entityId]);

  const specificWidgetType = getSpecificWidgets();
  const isShowSpecificWidget = specificWidgetType !== null;
  const isShowChinaScienceDiscoveryWidget =
    specificWidgetType === TopicDiscoveryWidgetType.ChinaScienceDiscovery;
  const isShowHundredTopTablesDiscoveryWidget =
    specificWidgetType === TopicDiscoveryWidgetType.HundredTopTables;
  const isShowOpenQuestionsSeriesDiscoveryWidget =
    specificWidgetType === TopicDiscoveryWidgetType.OpenQuestionsSeriesDiscovery;
  const isShowPostMagazineDiscoveryWidget =
    specificWidgetType === TopicDiscoveryWidgetType.PostMagazine;

  const lazyLoadData = useLazyLoadQuery<hooksTopicWidgetLazyLoadQuery>(
    graphql`
      query hooksTopicWidgetLazyLoadQuery(
        $isShowChinaScienceDiscoveryWidget: Boolean!
        $isShowHundredTopTablesDiscoveryWidget: Boolean!
        $isShowOpenQuestionsSeriesDiscoveryWidget: Boolean!
        $isShowPostMagazineDiscoveryWidget: Boolean!
        $scmpPlusPaywallTypeIds: [String!]
      ) {
        ...discoveryWidgetPostMagazineQueueQuery @include(if: $isShowPostMagazineDiscoveryWidget)
        ...chinaScienceDiscoveryWidgetQuery @include(if: $isShowChinaScienceDiscoveryWidget)
        ...hundredTopTablesDiscoveryWidgetQuery @include(if: $isShowHundredTopTablesDiscoveryWidget)
        ...openQuestionsSeriesDiscoveryWidgetQuery
          @include(if: $isShowOpenQuestionsSeriesDiscoveryWidget)
          @arguments(scmpPlusPaywallTypeIds: $scmpPlusPaywallTypeIds)
      }
    `,
    {
      isShowChinaScienceDiscoveryWidget,
      isShowHundredTopTablesDiscoveryWidget,
      isShowOpenQuestionsSeriesDiscoveryWidget,
      isShowPostMagazineDiscoveryWidget,
      scmpPlusPaywallTypeIds: [scmpPlus.article.paywallTypeId],
    },
    {
      fetchPolicy: "store-or-network",
    },
  );

  const renderRecommendedForYou = () => (
    <AiArticleWidget aiEngineDatatype={AiEngineDataType.HomeRecommendedForYou} />
  );

  const renderPostMagazine = () => <PostMagazineDiscoveryWidget reference={lazyLoadData} />;
  const renderChinaScienceDiscoveryWidget = () => (
    <ChinaScienceDiscoveryWidget reference={lazyLoadData} />
  );
  const renderOpenQuestionsSeriesDiscoveryWidget = () => (
    <OpenQuestionsSeriesDiscoveryWidget reference={lazyLoadData} />
  );
  const render100TopTables = () => <HundredTopTablesDiscoveryWidget reference={lazyLoadData} />;

  const rhsModuleComponents = [RHSModule2, RHSModule3];

  const getTopicWidgets = () => {
    if (specificWidgetType !== null) {
      return [specificWidgetType, TopicDiscoveryWidgetType.RecommendedForYou] as TopicWidget[];
    }
    return [TopicDiscoveryWidgetType.RecommendedForYou] as TopicWidget[];
  };

  const topicWidgetComponentMap: Record<TopicWidget, () => ReactNode> = {
    [TopicDiscoveryWidgetType.ChinaScienceDiscovery]: renderChinaScienceDiscoveryWidget,
    [TopicDiscoveryWidgetType.HundredTopTables]: render100TopTables,
    [TopicDiscoveryWidgetType.OpenQuestionsSeriesDiscovery]:
      renderOpenQuestionsSeriesDiscoveryWidget,
    [TopicDiscoveryWidgetType.PostMagazine]: renderPostMagazine,
    [TopicDiscoveryWidgetType.RecommendedForYou]: renderRecommendedForYou,
  };

  const modules = getTopicWidgets();
  const selectedModules = hasSponsor ? modules.slice(0, 1) : modules;
  const renderModules = selectedModules.map(widget => topicWidgetComponentMap[widget]);

  const getMaxAdsNumber = (edgesLength: number, hasSponsor = false) => {
    const adsNumbers = selectedModules.length > 2 ? [26, 23, 15] : [26, 18, 15];
    const totalAdsNumbers = hasSponsor ? [26, 18, 11] : adsNumbers;
    const showAdsNumbers = [4, 3, 2];
    for (const [index, totalAdsNumber] of totalAdsNumbers.entries()) {
      if (edgesLength >= totalAdsNumber) {
        return showAdsNumbers[index];
      }
    }
    return 1;
  };

  const renderTopicAds = () => (
    <TopicAds
      isShowSpecificWidget={isShowSpecificWidget}
      maxAdsNumber={getMaxAdsNumber(contentItemLength, hasSponsor)}
      reference={data.topic}
    />
  );

  const widgets = renderModules.map((module, index) => {
    const Wrapper = rhsModuleComponents[index];
    const displayModuleCount = [15, 21];

    if (!(displayModuleCount[index] < contentItemLength)) return null;

    return (
      <Fragment key={index}>
        <Suspense>
          <Wrapper>{module?.()}</Wrapper>
        </Suspense>
      </Fragment>
    );
  });

  return { isShowSpecificWidget, render: { ads: renderTopicAds(), widgets } };
};
