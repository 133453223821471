/**
 * @generated SignedSource<<18fb9d7fc6a4dcd5532fc5618c93b5bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type topicRestContentsTopic$data = {
  readonly advertZone: string | null | undefined;
  readonly entityId: string;
  readonly " $fragmentType": "topicRestContentsTopic";
};
export type topicRestContentsTopic$key = {
  readonly " $data"?: topicRestContentsTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"topicRestContentsTopic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "topicRestContentsTopic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "version",
          "value": 2
        }
      ],
      "kind": "ScalarField",
      "name": "advertZone",
      "storageKey": "advertZone(version:2)"
    }
  ],
  "type": "Topic",
  "abstractKey": null
};

(node as any).hash = "a5c016994fa01cb71b50a1d2e0b3960f";

export default node;
