/**
 * @generated SignedSource<<c1b56722dfa6d5c6529dad12863a1a6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksTopicWidgetQuery$data = {
  readonly appConfig: {
    readonly json: unknown | null | undefined;
  } | null | undefined;
  readonly topic: {
    readonly entityId: string;
    readonly " $fragmentSpreads": FragmentRefs<"hooksTopic" | "topicAdsTopic" | "topicMetadataDisplayTopic">;
  };
  readonly " $fragmentType": "hooksTopicWidgetQuery";
};
export type hooksTopicWidgetQuery$key = {
  readonly " $data"?: hooksTopicWidgetQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksTopicWidgetQuery">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hooksTopicWidgetQuery"
};

(node as any).hash = "42b19c905dbd6cbef9309bd9ab253bda";

export default node;
