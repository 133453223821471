import { theme } from "@product/scmp-sdk";
import last from "lodash/last";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";
import type { FragmentRefs } from "relay-runtime";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { TargetingPaidType } from "scmp-app/components/advertisement/ad-slots/types";
import { getTopicAdsConfigs } from "scmp-app/components/topic/topic-ads/ads.config";
import {
  AdsSlotContainer,
  StyledTopStoriesItem,
  TopContentsLeftContainer,
  TopContentsRightContainer,
} from "scmp-app/components/topic/topic-top-contents/styles";
import type { topicRestContentsTopic$key } from "scmp-app/queries/__generated__/topicRestContentsTopic.graphql";

import { AdsRest, TopContentsRestContainer, TopContentsRestContents } from "./styles";

export type Props = {
  currentTopicReference?: string;
  index: number;
  reference: topicRestContentsTopic$key;
  restContents: {
    readonly " $fragmentSpreads": FragmentRefs<
      | "homeSecondaryContentItemContent"
      | "sectionTopLeadPrimaryContentItemContent"
      | "topStoriesItemContent"
    >;
  }[];
};

export const TopicRestContents: FunctionComponent<Props> = ({
  currentTopicReference,
  index,
  reference,
  restContents,
}) => {
  const data = useFragment(
    graphql`
      fragment topicRestContentsTopic on Topic {
        entityId
        advertZone(version: 2)
      }
    `,
    reference,
  );

  if (!restContents) return null;

  const [first, second, ...rest] = restContents;
  const isShowAds = (index + 1) % 3 === 0; // Show ads in every third block

  const adsConfigs = getTopicAdsConfigs(data?.entityId ?? undefined, data?.advertZone ?? undefined);
  const lastAdsConfig = last(adsConfigs) ?? [];

  const renderLastItemAd = () => {
    if (!isShowAds) return null;
    return (
      <AdsSlotContainer>
        <AdSlot
          adUnit="d_native2c"
          breakpoint={theme.breakpoints.up("desktop")}
          customizedAdUnitTargeting="dnative-7b"
          sizes={[[1, 1], "fluid"]}
          targeting={{
            paid: TargetingPaidType.NotArticle,
            sctid: data?.entityId,
          }}
          zone={data?.advertZone ?? "default"}
        />
        <AdSlot
          adUnit="m_native2c"
          breakpoint={theme.breakpoints.only("tablet")}
          customizedAdUnitTargeting="dnative-7b"
          sizes={[[1, 1], "fluid"]}
          targeting={{
            paid: TargetingPaidType.NotArticle,
            sctid: data?.entityId,
          }}
          zone={data?.advertZone ?? "default"}
        />
        <AdSlot
          adUnit="m_native2c"
          breakpoint={theme.breakpoints.only("mobile")}
          customizedAdUnitTargeting="dnative-7b"
          sizes={[[1, 1], "fluid"]}
          targeting={{
            paid: TargetingPaidType.NotArticle,
            sctid: data?.entityId,
          }}
          zone={data?.advertZone ?? "default"}
        />
      </AdsSlotContainer>
    );
  };

  return (
    <TopContentsRestContainer key={"rest" + index}>
      <TopContentsRestContents>
        <TopContentsLeftContainer>
          {first && (
            <StyledTopStoriesItem
              currentTopicReference={currentTopicReference}
              reference={first}
              topicLinkVariant={{ type: "secondary" }}
              variant="primary"
              withSection={false}
              withTopic
            />
          )}
          {second && (
            <StyledTopStoriesItem
              currentTopicReference={currentTopicReference}
              reference={second}
              topicLinkVariant={{ type: "secondary" }}
              variant="secondary"
              withSection={false}
              withTopic
            />
          )}
        </TopContentsLeftContainer>
        <TopContentsRightContainer>
          {rest.map((item, itemIndex) => (
            <StyledTopStoriesItem
              currentTopicReference={currentTopicReference}
              isShowImage={!isShowAds && itemIndex === rest.length - 1}
              key={itemIndex}
              reference={item}
              topicLinkVariant={{ type: "secondary" }}
              variant="secondary"
              withSection={false}
              withTopic
            />
          ))}
          {isShowAds && renderLastItemAd()}
        </TopContentsRightContainer>
      </TopContentsRestContents>
      {isShowAds && (
        <AdsRest>
          {lastAdsConfig.map(
            (config, configIndex) => config && <AdSlot {...config} key={configIndex} />,
          )}
        </AdsRest>
      )}
    </TopContentsRestContainer>
  );
};

TopicRestContents.displayName = "TopicRestContents";
