/**
 * @generated SignedSource<<dc143b00d999d24c506e8e60f96a7e91>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type topicTopicPageTopic$data = {
  readonly contents: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly entityUuid: string;
        readonly " $fragmentSpreads": FragmentRefs<"homeSecondaryContentItemContent" | "sectionTopLeadPrimaryContentItemContent" | "topStoriesItemContent">;
      };
    }>;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "topicTopicPageTopic";
};
export type topicTopicPageTopic$key = {
  readonly " $data"?: topicTopicPageTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"topicTopicPageTopic">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "contents"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "applicationId"
    },
    {
      "defaultValue": 28,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "scmpPlusPaywallTypeIds"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./topicTopicPagePaginationQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "topicTopicPageTopic",
  "selections": [
    {
      "alias": "contents",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "paywallTypeIds",
              "variableName": "scmpPlusPaywallTypeIds"
            }
          ],
          "kind": "ObjectValue",
          "name": "exclude"
        },
        {
          "fields": [
            {
              "items": [
                {
                  "kind": "Variable",
                  "name": "applicationIds.0",
                  "variableName": "applicationId"
                }
              ],
              "kind": "ListValue",
              "name": "applicationIds"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "direction": "DESC",
            "field": "PUBLISHED_DATE"
          }
        }
      ],
      "concreteType": "ContentConnection",
      "kind": "LinkedField",
      "name": "__topicTopicPage__author_contents_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "entityUuid",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "homeSecondaryContentItemContent"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "sectionTopLeadPrimaryContentItemContent"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "topStoriesItemContent"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Topic",
  "abstractKey": null
};
})();

(node as any).hash = "cedec22b4b0a4941a483b6d4f82f03a3";

export default node;
